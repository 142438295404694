import { Service } from 'netlicensing-client';

export const updateRecurringStatus = (context, transactionNumber, activate) => Service
  .request(context, 'post', `userinterface/${transactionNumber}/recurring/update-status`, { active: activate });

export const cancelRecurring = (context, transactionNumber) => Service
  .request(context, 'post', `userinterface/${transactionNumber}/recurring/cancel`);

export const nextRecurringTime = (context, transactionNumber) => Service
  .request(context, 'get', `userinterface/${transactionNumber}/recurring/get-next`);

export default {
  updateRecurringStatus,
  cancelRecurring,
  nextRecurringTime,
};
